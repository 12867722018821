<!--警报管理--警报管理页-->
<template>
  <div id="warning">
    <warningRecord :msg="msg" :baseInfo="list"></warningRecord>
  </div>
</template>

<script>
// import warningRecord from "../setManagement/warning/record/warningRecord";
import warningRecord from "../setManagement/warning/record/alarm";//更改警报页面 modified by li_yj on 2022-10-08

export default {
  name: "equipment",  
  data() {
    return {
      loading: false, 
      name:    this.$t('notificationEquipment.a1'),
      // msg:   this.$route.query.msg,//父组件传入参数
    };
  },
  computed: {
    list() {
      let list = this.$store.getters.oneMenu;      
      console.log("equipment menu list:", list);
      return list;
    },
    msg(){
      return this.$route.query.msg;//父组件传入参数
    }
  },  
  methods: {
    callback(key) {   
    },
    // 初始化三级导航栏
    getlist() {
      this.$store.dispatch("menu/getlist");
    },
  },
  mounted() {
    // 调用获取一级导航栏菜单的方法
    this.getlist();
  },
  components: {
    "warningRecord":warningRecord,
  },
};
</script>

<style scoped>
#warning {
  height:100%;
  /* margin: 20px; */
  position: relative;
  /* padding: 20px 20px 20px 20px; */
  background: #ffffff;
  overflow: auto;
}
</style>